import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthManagerService {
  enteredEmail = '';
  private myRedirectUrl: string | null = null;
  private myIsSaml = true;
  private myIsPartnerLogin = false;

  constructor() {}

  set redirectUrl(url: string | null) {
    this.myRedirectUrl = url;
  }

  get redirectUrl(): string | null {
    return this.myRedirectUrl;
  }

  set isSaml(val: boolean) {
    this.myIsSaml = val;
  }

  get isSaml(): boolean {
    return this.myIsSaml;
  }

  set isPartnerLogin(val: boolean) {
    this.myIsPartnerLogin = val;
  }

  get isPartnerLogin(): boolean {
    return this.myIsPartnerLogin;
  }
}
