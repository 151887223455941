import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject, throwError } from 'rxjs';
import { filter, mergeMap, share, take } from 'rxjs/operators';
import { ApiSettings, gridscale as gridscaleAPI } from '@gridscale/gsclient-js';
import { Store } from '@ngrx/store';
import { getSessionData } from '../store/session/session.selectors';
import { ConfigService } from '@gridscale/ingrid/helper/services/config.service';
import { environment } from './../../../environments/environment';
import { getAuthData } from '../store/session/session.reducer';
import * as _ from 'lodash';
import { log } from '../tools/console';

export let apiClient: gridscaleAPI.Client;

// unwrap Promise Return Type... (@see https://jpwilliams.dev/how-to-unpack-the-return-type-of-a-promise-in-typescript)
type Unwrap<T> = T extends Promise<infer U> ? U : T extends (...args: any) => Promise<infer U> ? U : T extends (...args: any) => infer U ? U : T;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  endpoint?: string;
  client: gridscaleAPI.Client;

  public readonly ready$ = new BehaviorSubject(false);

  private sessionChecked = false;

  constructor(store: Store, config: ConfigService) {
    log('%c[API Service] Load API-Serive', 'color:green');
    const options: ApiSettings = {};
    if (environment.endpointOverrides) {
      options.endpointOverrides = environment.endpointOverrides;
    }

    let user_uuid = '';
    let token = '';
    const localAuthdata = getAuthData();

    if (localAuthdata && localAuthdata.token) {
      token = localAuthdata.token;
      user_uuid = localAuthdata.user_uuid;
      this.sessionChecked = true;
    }

    this.client = new gridscaleAPI.Client(token, user_uuid, options);
    apiClient = this.client;
    _.set(window, ['apiClient'], apiClient);

    store.select(getSessionData).subscribe(_session => {
      if (_session) {
        log('%c[API Service] Inject or Update Session in API-Client', 'color:orange', _session);
        this.client.setToken(_session.token!, _session.user_uuid!);
      } else {
        this.client.setToken('', '');
      }
      this.sessionChecked = true;
      this.checkReady();
    });

    config.ready$
      .pipe(
        filter(loaded => loaded === true),
        take(1)
      )
      .subscribe(loaded => {
        this.endpoint = config.coreAPI;
        this.client.setEndpoint(this.endpoint);
        this.checkReady();
      });

    this.client.setApiClient('panel');
  }

  private checkReady() {
    if (this.endpoint && this.sessionChecked) {
      this.ready$.next(true);
    } else {
      this.ready$.next(false);
    }
  }

  /**
   * Wrapper function: Calls the _function on _object in the @gridscle/api Client and returns an Observable from its Promise
   * !!! Always use this wrapper function to speak to the API, as we need to get rid of promises in tests !!!
   *
   * @param _object The gridscaleObject to access (e.g. 'Server', 'Storage' ...)
   * @param _function The function to call on the API object (e.g. 'list', 'get', 'patch' ...)
   * @param _functionArgs arguments for that function
   */
  $<T>(_object: string, _function: string, ..._functionArgs: any): Observable<Unwrap<T>> {
    return this.ready$.pipe(
      filter(ready => ready === true),
      take(1),
      mergeMap(() => {
        if (_object === '' || (typeof _.get(this.client, [_object, _function]) === 'function')) {
          if (_object === '') {
            // For watchrequests
            return from(_.get(this.client, [_function]).apply(this.client, _functionArgs)) as Observable<Unwrap<T>>;
          } else {
            // All others...
            return from(_.get(this.client, [_object, _function]).apply(_.get(this.client, [_object]), _functionArgs)) as Observable<Unwrap<T>>;
          }
        } else {
          return throwError(
            'api.service -> $(): ' +
            (typeof _.get(this.client, [_object]) === 'undefined' ? 'the object ' + _object + ' is undefined' : 'function ' + _function + ' does not exist on ' + _object)
          );
        }
      }),
      share()
    ) as Observable<Unwrap<T>>;
  }

  /**
   * Wrapper function: Calls the method that returns a promise and returns an Observable from its Promise
   * use this for example for the link functions
   *
   * @param _promiseReturningFunc the api method that would return the promise (e.g. 'Server.list', 'Storage.patch' ...)
   * @param _callContext The context where this function should be called
   * @param _functionArgs arguments for that function
   */
  fromFunction$<T>(_promiseReturningFunc: () => Promise<Unwrap<T>>, _callContext: any, ..._functionArgs: any): Observable<Unwrap<T>> {
    return this.ready$.pipe(
      filter(ready => ready === true),
      take(1),
      mergeMap(() => {
        return from(_promiseReturningFunc.apply(_callContext, _functionArgs)) as Observable<Unwrap<T>>;
      }),
      share()
    ) as Observable<Unwrap<T>>;
  }

  validateToken() {
    if (this.sessionChecked) {
      return this.client.validateToken();
    } else {
      // Intante Reject
      return new Promise(function (resolve, reject) { reject(); });
    }

  }

  /**
   * returns the api client, can be used to inject fake api client to test
   */
  getApiClient() {
    return apiClient;
  }
}
