import { GridscaleObjects } from '../GridscaleObjects.js';
class MarketplaceApplicationInstance extends GridscaleObjects {
  constructor(_api) {
    super(_api, '/marketplace/v1/projects');
  }
  /**
   * Create a new application instance
   */
  create(_attributes, _callback) {
    return new Promise((resolve, reject) => {
      this._api.post(this._basepath + '/' + _attributes.project_uuid + '/application-instances', _attributes, _callback).then(res => {
        if (res.result?.data?.id) {
          resolve({
            ...res,
            result: {
              ...res.result,
              object_uuid: res.result.data.id,
              request_uuid: res.response.headers.get('x-correlate-id')
            }
          });
        } else {
          reject(new Error('unknown response'));
        }
      }).catch(e => reject(e));
    });
  }
  /**
   * @deprecated please use `listInstances` method for this object type
   */
  list(_options, _callback) {
    return new Promise((resolve, reject) => {
      reject(new Error('Unsupported method MarketplaceApplicationInstance.list: Please use `listInstances` method for this object type!'));
    });
  }
  /**
   * List Application instances
   */
  listInstances(_project_uuid, _options, _callback) {
    // Get Defaults
    const requestOptions = this._buildRequestOptions(_options);
    return this._api.get(this._basepath + '/' + _project_uuid + '/application-instances', requestOptions, _callback);
  }
  /**
   * @deprecated: Please use `getInstance` method for this object type
   */
  get(_uuid, _callback) {
    return new Promise((resolve, reject) => {
      reject(new Error('Unsupported method MarketplaceApplicationInstance.list: Please use `getInstance` method for this object type!'));
    });
  }
  /**
   * get Application instance detail
   */
  getInstance(_project_uuid, _uuid, _callback) {
    return this._api.get(this._basepath + '/' + _project_uuid + '/application-instances/' + _uuid, {}, _callback);
  }
  /**
   * @deprecated: Please use `requestDeleteInstance` method for this object type
   */
  remove(_uuid, _callback) {
    return new Promise((resolve, reject) => {
      reject(new Error('Unsupported method MarketplaceApplicationInstance.list: Please use `requestDeleteInstance` method for this object type!'));
    });
  }
  /**
   * request deletion of the instance
   */
  requestDeleteInstance(_project_uuid, _uuid, _callback) {
    return this._api.remove(this._basepath + '/' + _project_uuid + '/application-instances/' + _uuid, _callback);
  }
}
export { MarketplaceApplicationInstance };
