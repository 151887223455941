import { api as globalApi, APIClass } from './api.js';
import { Server } from './Objects/Server.js';
import { Storage } from './Objects/Storage.js';
import { Network } from './Objects/Network.js';
import { IP } from './Objects/IP.js';
import { ISOImage } from './Objects/ISOImage.js';
import { SSHKey } from './Objects/SSHKey.js';
import { Template } from './Objects/Template.js';
import { Location } from './Objects/Location.js';
import { ObjectStorage } from './Objects/ObjectStorage.js';
import { Label } from './Objects/Label.js';
import { Loadbalancer } from './Objects/Loadbalancer.js';
import { Events } from './Objects/Events.js';
import { Firewall } from './Objects/Firewall.js';
import { PAAS } from './Objects/PAAS.js';
import { Deleted } from './Objects/Deleted.js';
import { PaasServiceTemplate } from './Objects/PaasServiceTemplate.js';
import { PaasService } from './Objects/PaasService.js';
import { PaasSecurityZone } from './Objects/PaasSecurityZone.js';
import { PaasServiceMetrics } from './Objects/PaasServiceMetrics.js';
import { MarketplaceApplication } from './Objects/MarketplaceApplication.js';
import { MarketplaceApplication as ServiceMarketplaceApplication } from './Objects/ServiceMarketplace/MarketplaceApplication.js';
import { Certificate } from './Objects/Certificate.js';
import { BackupLocation } from './Objects/BackupLocation.js';
import { MarketplacePlan } from './Objects/ServiceMarketplace/MarketplacePlan.js';
import { MarketplacePlanSettings } from './Objects/ServiceMarketplace/MarketplacePlanSettings.js';
import { MarketplaceApplicationInstance } from './Objects/ServiceMarketplace/MarketplaceApplicationInstance.js';
import { MarketplaceVersion } from './Objects/ServiceMarketplace/MarketplaceVersion.js';
import { GPU } from './Objects/GPU.js';
import { VERSION } from './.version.js';
/**
 * generate Client Class for all Connections
 * test
 */
class GridscaleClient {
  // Types
  Server;
  Storage;
  Network;
  IP;
  ISOImage;
  SSHKey;
  Template;
  Location;
  ObjectStorage;
  Label;
  Loadbalancer;
  Events;
  Firewall;
  Certificate;
  BackupLocation;
  GPU;
  /**
   * @deprecated
   */
  PAAS;
  PaasService;
  PaasServiceTemplate;
  PaasSecurityZone;
  Deleted;
  MarketplaceApplication;
  ServiceMarketplaceApplication;
  ServiceMarketplaceApplicationInstance;
  ServiceMarketplacePlan;
  ServiceMarketplacePlanSettings;
  ServiceMarketplaceVersion;
  watchRequest;
  VERSION = VERSION;
  myapi;
  /**
   * Init Client with Default Values
   *
   *
   * @param _token Security Token
   * @param _userId UUID of User
   * @param _options
   * @param _isolated (if true, use isolated api which can be used alongside other instances. Default behavior is shared settings/tokens between the client instances)
   */
  constructor(_token, _userId, _options = {}, _isolated = false) {
    if (_isolated) {
      this.myapi = new APIClass();
    }
    // Store Security Tokens
    this.api.storeToken(_token, _userId);
    // Store advanced Options
    this.api.setOptions(_options);
    // Call Subtypes
    this.Server = new Server(this.api);
    this.Storage = new Storage(this.api);
    this.Network = new Network(this.api);
    this.IP = new IP(this.api);
    this.ISOImage = new ISOImage(this.api);
    this.SSHKey = new SSHKey(this.api);
    this.Template = new Template(this.api);
    this.Location = new Location(this.api);
    this.ObjectStorage = new ObjectStorage(this.api);
    this.Label = new Label(this.api);
    this.Loadbalancer = new Loadbalancer(this.api);
    this.Events = new Events(this.api);
    this.Firewall = new Firewall(this.api);
    this.PAAS = new PAAS(this.api);
    this.PaasServiceTemplate = new PaasServiceTemplate(this.api);
    this.PaasService = new PaasService(this.api);
    this.PaasSecurityZone = new PaasSecurityZone(this.api);
    this.Deleted = new Deleted(this.api);
    this.MarketplaceApplication = new MarketplaceApplication(this.api);
    this.ServiceMarketplaceApplication = new ServiceMarketplaceApplication(this.api);
    this.ServiceMarketplaceApplicationInstance = new MarketplaceApplicationInstance(this.api);
    this.ServiceMarketplacePlan = new MarketplacePlan(this.api);
    this.ServiceMarketplacePlanSettings = new MarketplacePlanSettings(this.api);
    this.ServiceMarketplaceVersion = new MarketplaceVersion(this.api);
    this.Certificate = new Certificate(this.api);
    this.BackupLocation = new BackupLocation(this.api);
    this.GPU = new GPU(this.api);
    this.watchRequest = this.api.watchRequest.bind(this.api);
  }
  /**
   * Set the identifier of the client (used in X-Api-Client Header)
   * @param _client
   */
  setApiClient(_client) {
    this.api.storeClient(_client);
  }
  /**
   * Set a new Token and User-UUID
   * @param _token
   * @param _userId
   */
  setToken(_token, _userUUID) {
    this.api.storeToken(_token, _userUUID);
  }
  /**
   * Set the HTTP endpoint of the API
   * @param _endpoint
   */
  setEndpoint(_endpoint) {
    this.api.setOptions({
      endpoint: _endpoint
    });
  }
  /**
   * Inject a custom fetch method, otherwise the API will decide if to use the browser's fetch method or a polyfill
   * @param _fetch
   */
  setFetch(_fetch) {
    this.api.setOptions({
      fetch: fetch
    });
  }
  /**
   * set addiotional headers
   */
  setAdditionalHeaders(additionalHeaders) {
    this.api.setOptions({
      additionalHeaders
    });
  }
  /**
   * Add an additional logger callback, called whenever an error is happening
   * @param _callback
   */
  addLogger(_callback) {
    this.api.addLogger(_callback);
  }
  /**
   * Calls the Validate Token Endpoint of the API
   * @returns HTTP Promise
   */
  validateToken() {
    return this.api.get('/validate_token');
  }
  /**
   * Get the paas service metrics API which is a special one as the service-uuid is required early in the URL
   * @param _serviceUUID
   */
  PaasServiceMetrics(_serviceUUID) {
    return new PaasServiceMetrics(this.api, _serviceUUID);
  }
  /**
   * Stringifies all non string-values of a HTTP Response (e.g. headers)
   * @param object
   * @deprecated
   */
  stringifyResponseRequest(object) {
    // tslint:disable-next-line: no-any
    const tmp = {};
    for (let _key in object) {
      if (object.hasOwnProperty(_key)) {
        const _val = object[_key];
        if (_val instanceof Headers) {
          tmp[_key] = {};
          _val.forEach((_h, _k) => {
            tmp[_key][_k] = _h;
          });
        } else if (_val instanceof Request) {
          tmp[_key] = this.stringifyResponseRequest(_val);
        } else if (['string', 'number', 'object', 'boolean'].indexOf(typeof _val) >= 0) {
          tmp[_key] = _val;
        }
      }
    }
    ;
    return tmp;
  }
  get api() {
    return this.myapi || globalApi;
  }
}
export { GridscaleClient as Client };
