import * as _ from 'lodash';

/**
 * Allow logging only if we are on localhost / stage or with the browser extesion
 *
 * @param args  Default console.log argumentes
 */
export const log = (...args: any[]) => {
  let allowLog = false;

  // Browser Extension
  if (_.get(window, ['gs_debugging'], false)) allowLog = true;

  // Localhost
  if (window.location.hostname == 'localhost') allowLog = true;

  // Staging
  if (window.location.hostname.search('gridscale.dev') >= 0) allowLog = true;

  // Demo
  if (window.location.hostname.search('demo.gridscale.io') >= 0) allowLog = true;

  // Log if allowed
  allowLog && console.log(...args);
};
