import { GridscaleObjects } from '../GridscaleObjects.js';
class MarketplaceApplication extends GridscaleObjects {
  constructor(_api) {
    super(_api, '/marketplace/v1/applications');
  }
  catalog(_options, _callback) {
    return this._api.get('/marketplace/v1/catalog', _options, _callback);
  }
  catalogForAccount(account_uuid, _options, _callback) {
    return this._api.get('/marketplace/v1/catalog/' + account_uuid, _options, _callback);
  }
}
export { MarketplaceApplication };
