import { GridscaleObjects } from '../GridscaleObjects.js';
class MarketplacePlanSettings extends GridscaleObjects {
  constructor(_api) {
    super(_api, '/marketplace/v1/plans');
  }
  get(_planUuid, _callback) {
    return this._api.get(this._basepath + '/' + _planUuid + '/settings-schema', {}, _callback);
  }
}
export { MarketplacePlanSettings };
