import { GridscaleObjects } from './GridscaleObjects.js';
class GPU extends GridscaleObjects {
  constructor(_api) {
    super(_api, '/objects/gpus');
  }
  listFlavors(_options, _callback) {
    // Get Defaults
    const requestOptions = this._buildRequestOptions(_options);
    // Set Callback
    if (typeof _options === 'function' && _callback === undefined) {
      _callback = _options;
    }
    return this._api.get('/objects/gpu_flavors', {
      ...requestOptions,
      limit: 100
    }, _callback);
  }
}
export { GPU };
