import { GridscaleObjects } from './GridscaleObjects.js';
import { PaasServiceMetrics } from './PaasServiceMetrics.js';
class PaasService extends GridscaleObjects {
  api;
  constructor(_api) {
    super(_api, '/objects/paas/services');
    this.api = _api;
  }
  listMetrics(_uuid, _callback) {
    return new PaasServiceMetrics(this._api, _uuid).list({}, _callback);
  }
  renewCredentials(_uuid) {
    return this._api.patch(this._basepath + '/' + _uuid + '/renew_credentials', {});
  }
}
export { PaasService };
