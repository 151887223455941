import { Injectable } from '@angular/core';
import * as Fingerprint2 from 'fingerprintjs2';
import { Location } from '@angular/common';
import { Observer } from 'rxjs';
import { NavigationEnd, Router, Event } from '@angular/router';
import { ConfigService } from '@gridscale/ingrid/helper/services/config.service';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { uniqueId } from 'lodash';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private myFingerPrint = '';

  constructor(private readonly location: Location, private router: Router, private readonly configService: ConfigService,
    private readonly actions$: ActionsSubject) {
    _.set(window, ['dataLayer'], _.get(window, ['dataLayer'], []));
    this.init();
  }

  trackLogin() {
    this.pushToDataLayer({
      event: 'login',
      method: 'default',
    });
  }

  trackSubmitLoginOtp() {
    this.pushToDataLayer({
      event: 'login',
      method: 'otp',
    });
  }

  trackSignupSuccess(uuid: string, t: any, res: any, observer: Observer<string>) {
    this.pushToDataLayer({
      event: 'sign_up',
      method: 'panel',
      eventCallback: () => {
        // clearTimeout(t);

        setTimeout(() => {
          if (res.magiclink) {
            //   window.location.href = res.magiclink.replace('http://','https://');;
          } else {
            observer.next('success');
          }
        }, 500);
      }
    });
  }

  trackSignupFail() {
    this.pushToDataLayer({
      event: 'sign_up',
      method: 'panel-error',
    });
  }

  trackFormSubmitPwRecover() {
    this.pushToDataLayer({
      event: 'password_reset'
    });
  }

  trackMagiclinkSuccess() {
    this.pushToDataLayer({
      event: 'login',
      method: 'magic-link',
    });
  }

  trackMagicLinkFailed() {
    this.pushToDataLayer({
      event: 'login',
      method: 'magic-link-error',
    });
  }

  get fingerPrint() {
    return this.myFingerPrint;
  }

  private init() {
    try {
      Fingerprint2.getV18({}, result => {
        this.myFingerPrint = result;
      });
    } catch (e) { }

    /**
     * track all router evetns as gs_page_view Events to handle them
     */
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.pushToDataLayer({
          event: 'gs_page_view',
          page_location: this.location.path().replace(/\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/g, 'UUID').split('?')[0],
          page_title: this.location.path().replace(/\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/g, 'UUID').replace(/^\//g, '').replace(/\//g, ' - ').split('?')[0]
        });
      }
    });


  }


  // Allow to chache all Times, that we are tracking (Can be more or just broken once...)
  private CacheTimes: Record<string, Date> = {};

  /**
   * Stores the now to be able to calculate the time went by later
   *
   * @returns Unique ID for this event
   */
  public trackTimeStart() {
    const newID = uniqueId('time_');
    this.CacheTimes[newID] = new Date();
    return newID
  }

  /**
   * Will just kill the _name datapoint from memory
   *
   * @param _name Unique ID for this tracking event
   */
  public trackTimeClear(_name: keyof typeof this.CacheTimes) {
    if (this.CacheTimes[_name]) {
      delete this.CacheTimes[_name];

    }

  }

  /**
   * Will Stop the timer, calculates the time and pushes a unique event to the datalayer.
   * Right now, we will not add any other information, so we need to take the page_view URL to get the info, what object that event is for. We can later extend the tracking
   *
   * @param _name Unique ID for this tracking event
   */

  public trackTimeStop(_object_type_name: string, _name: keyof typeof this.CacheTimes, _isCreateMode: boolean) {
    if (this.CacheTimes[_name]) {
      const time = Date.now() - this.CacheTimes[_name].getTime();
      this.trackTimeClear(_name);
      this.trackEvent('gs_time_to_complete', {
        "duration": time,
        "create_more": _isCreateMode,
        "object_type": _object_type_name
      });
    }
  }




  public trackEvent(_event_name: string, _event_data = {}) {
    this.pushToDataLayer({
      event: 'gs_custom_event',
      event_name: _event_name,
      ..._event_data
    });
  }



  private pushToDataLayer(data: any) {
    _.get(window, ['dataLayer'], []).push(data);
  }


  /**
   * Allow a easy way to listen to default CRUD Events for Objects and push them into the DataLayer
   *
   * @param _type Type of the Object!
   * @param _actionMapping Mapping of ngrx Actions to listen on
   */
  public watchObjectCRUD(_object_type_name: string, _actionMapping: { create?: any, remove?: any, patch?: any }) {


    for (const actionName in _actionMapping) {
      if (Object.prototype.hasOwnProperty.call(_actionMapping, actionName)) {
        const action = _actionMapping[actionName as keyof typeof _actionMapping];
        this.actions$.pipe(
          ofType(action),
        ).subscribe(action => {
          this.pushToDataLayer({
            event: 'gs_object_event',
            object_action: actionName,
            object_type: _object_type_name
          });
        });
      }
    }

  }


}
