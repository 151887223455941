import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MiddlewareService } from './../@shared/services/middleware.service';
import { Session } from './../@shared/models/session.model';
import { TrackingService } from './tracking.service';
import { Store } from '@ngrx/store';
import { loginSuccess, logoutSuccess, sessionInvalid, sessionValid } from './../@shared/store/session/session.actions';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { filter, share, take } from 'rxjs/operators';
import { LoginPartner } from './../@shared/models/login-partner.model';
import { LoginProject } from './../@shared/models/login-project.model';
import { ApiService } from './../@shared/services/api.service';
import { log } from './../@shared/tools/console';
import { find } from 'lodash';
import { AuthManagerService } from './auth-manager.service';
import { InviteService } from './invite/invite.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private mySession = new BehaviorSubject<boolean | null>(null);
  private myEmail: string | undefined;
  private myPassword: string | undefined;
  private myOtp: string | undefined;
  private myToken: string | undefined;
  private myDefaultLoginSetting = new BehaviorSubject<string | undefined>(undefined);
  private myProjects = new BehaviorSubject<LoginProject[]>([]);
  private myPartner = new BehaviorSubject<LoginPartner | null>(null);
  private readonly magicLinkUrl = '/v3/user/magiclink';
  private projectsUrl = '/v3/users/current/projects';

  private partnerRedirectUrl = '';

  constructor(
    private readonly middlewareService: MiddlewareService,
    private readonly api: ApiService,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly store: Store,
    private readonly trackingService: TrackingService,
    private readonly authManagerService: AuthManagerService,
    private readonly inviteService: InviteService
  ) {
    const params = (new URL(document.location.toString())).searchParams;
    const redirectUrl = params.get('redirectUrl');

    if (redirectUrl) {
      this.partnerRedirectUrl = '&redirectUrl=' + redirectUrl;
    }
  }

  login(email: string | undefined, password: string | undefined, token?: string): Observable<void | string> {
    this.myEmail = email;
    this.myPassword = password;
    this.myToken = token;
    this.authManagerService.isSaml = false;

    this.trackingService.trackLogin();

    const obs = this.makeRequest('/login', {
      email,
      password,
      magiclink: this.myToken,
      hash: this.trackingService.fingerPrint,
      invitetoken: this.inviteService.inviteToken,
      force_login: true
    }).pipe(share());

    obs.subscribe(_response => {
      // If the User only has a relation to a Partner, he will automaticly get logginto the PaPa
      if (_response && _response.body && _response.body.partner_login && _response.body.one_time_token) {
        this.jumpToPartner(_response);
      } else {
        this.store.dispatch(loginSuccess({ data: _response.body }));
      }
    });

    return obs;
  }

  loginToPartner() {
    const partner = this.myPartner.getValue();
    const obs = new Observable<any>(observer => {
      if (this.authManagerService.isSaml === false) {
        this.makeRequest('/login', {
          email: this.myEmail,
          password: this.myPassword,
          partner_login: true,
          force_login: true,
          magiclink: this.myToken,
          otp: this.myOtp
        }).subscribe(
          response => {
            if (response && response.body && response.body.one_time_token) {
              this.jumpToPartner(response);
            }

            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );

      } else if (partner) {
        this.makeRequest('/login', { partner_uuid: partner.partner_uuid, partner_login: true }).subscribe(
          response => {
            if (response && response.body && response.body.one_time_token) {
              this.jumpToPartner(response);
            }

            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
      }
    }).pipe(share());

    return obs;
  }

  /**
   * Add Response
   *
   * @param response Response Payload
   */
  jumpToPartner(response: any) {
    window.location.href = '/Partner/?oneTimeToken=' + response.body.one_time_token + this.partnerRedirectUrl;
  }

  loginToProject(project_uuid: string, token?: string, otp?: string): Observable<any> {
    let obs;

    if (this.authManagerService.isSaml === false) {
      obs = this.makeRequest('/login', {
        email: !token ? this.myEmail : undefined,
        password: !token ? this.myPassword : undefined,
        project_uuid,
        magiclink: token || this.myToken,
        invitetoken: this.inviteService.inviteToken,
        hash: this.trackingService.fingerPrint,
        otp: otp || this.myOtp,
        force_login: true
      }).pipe(share());
    } else {
      obs = this.makeRequest('/login/saml/acs', { project_uuid }).pipe(share());
    }

    obs.subscribe(_response => this.store.dispatch(loginSuccess({ data: _response.body })));

    return obs;
  }

  loginWithOtp(otp: string, token: string | undefined): Observable<any> {
    this.myOtp = otp;
    if (token !== undefined) {
      this.myToken = token;
    }

    this.trackingService.trackSubmitLoginOtp();

    const obs = this.makeRequest('/login', {
      email: !this.myToken ? this.myEmail : undefined,
      password: !this.myToken ? this.myPassword : undefined,
      otp,
      magiclink: this.myToken,
      invitetoken: this.inviteService.inviteToken,
      hash: this.trackingService.fingerPrint,
      force_login: true
    }).pipe(share());

    obs.subscribe(_response => {
      if (_response && _response.body && _response.body.partner_login && _response.body.one_time_token) {
        this.jumpToPartner(_response);
      } else {
        this.store.dispatch(loginSuccess({ data: _response.body }));
      }
    });
    return obs;
  }

  getProjects(_contract_uuid?: string): Observable<LoginProject[]> {
    if (_contract_uuid) {
      return this.makeRequest('/login/saml/projects/' + _contract_uuid, undefined, 'get');
    } else {
      return this.makeRequest(this.projectsUrl, undefined, 'get');
    }
  }

  logout(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.middlewareService.post('/logout', {}, [401]).subscribe(
        (session: Session) => {
          this.mySession.next(null);
          this.inviteService.resetInviteToken();
          observer.next(true);

          // reset store by trigger logout success action
          this.store.dispatch(logoutSuccess());
          observer.complete();
        },
        error => {
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

  isLoggedInSessionless(_forceFetch = false): Observable<boolean> {
    return new Observable<boolean>(observer => {
      log('%c[AUTH Service] Check if Session is Valid', 'color:orange');
      this.api.ready$
        .pipe(
          filter(loaded => loaded === true),
          take(1)
        )
        .subscribe(loaded => {
          log('%c[AUTH Service] cAPI Ready to Check', 'color:orange');
          this.api.validateToken().then(
            _response => {
              log('%c[AUTH Service] Session is valid', 'color:green');
              this.mySession.next(true);
              observer.next(true);
              observer.complete();
              this.store.dispatch(sessionValid());
            },
            _error => {
              log('%c[AUTH Service] Session is not valid', 'color:red;');
              // Token invalid
              // We only kill the Session on a 401. network errors etc. should not kill them
              if (_error.message.search('401') > 0) {
                this.mySession.next(null);
                observer.next(false);
                observer.complete();
                this.store.dispatch(sessionInvalid());
              } else {
                log('%c[AUTH Service] Error while validating the token', 'color:red;');
              }
            }
          );
        });
    });
  }

  validateLoggedIn(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.api.validateToken().then(
        _response => {
          log('%c[AUTH Service] Session is Valid', 'color:green', _response);
          observer.next(true);
          observer.complete();
        },
        _error => {
          log('%c[AUTH Service] Session is Inalid', 'color:red;', _error);
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

  recover(email: string): Observable<any> {
    this.trackingService.trackFormSubmitPwRecover();
    const formData = new FormData();
    formData.append('email', email);
    // @TODO: Refactor to use json, when team php has made it's homeworks
    return this.makeRequest(this.magicLinkUrl, formData);
  }

  get session() {
    return this.mySession.getValue();
  }

  get session$() {
    return this.mySession.asObservable();
  }

  get projects$(): Observable<LoginProject[]> {
    return this.myProjects.asObservable();
  }

  get myDefaultLoginSetting$() {
    return this.myDefaultLoginSetting.asObservable();
  }

  get partner$() {
    return this.myPartner.asObservable();
  }

  private makeRequest(path: string, data: any, method: string = 'post'): Observable<any> {
    return new Observable<any>(observer => {
      ((this.http as any)[method] as any)(path, data).subscribe(
        (response: any) => {
          // If everything worked as expected, log the user in
          if (path !== this.magicLinkUrl && method === 'post' && !data.partner_login) {
            this.mySession.next(true);
            setTimeout(async () => {
              this.authManagerService.redirectUrl !== null ? await this.router.navigate([this.authManagerService.redirectUrl]) : await this.router.navigate(['/Dashboard']);
              this.myEmail = undefined;
              this.myPassword = undefined;
              this.myOtp = undefined;
              this.authManagerService.redirectUrl = null;
            }, 1000);
          }

          if ((path === this.projectsUrl || path.search('/saml/projects') >= 0) && method === 'get' && response.body !== null && response.body.projects) {
            if (response.body.auto_login_settings) {
              this.setLoginSettings(response.body.auto_login_settings);
            }

            if (response.body.partner) {
              this.setPartner(response.body.partner);
            }

            this.setProjects(response.body.projects, response.body?.auto_login_settings);
          }

          observer.next(response);
          observer.complete();
        },
        async (response: any) => {
          let myError = response.error && response.error.body && response.error.body.description ? response.error.body.description : 'AUTH.GENERAL.ERROR';
          const resCheck = myError.toLowerCase();

          if (response.url && response.url.indexOf('oneTimeToken') !== -1) {
            window.location.href = response.url;
            return;
          }

          switch (response.error?.code) {
            case 400:
              if (resCheck.indexOf('claimant is already related to contract from invitetoken') !== -1) {
                myError = 'AUTH.INVITE.EMAIL_ALREADY_RELATED';
              }
              break;

            case 401:
            case 403:
              if (resCheck.indexOf('no projects found') !== -1) {
                myError = 'AUTH.PROJECTS.NO_PROJECTS_FOUND';
              } else if (resCheck.indexOf('error on claiminvite') !== -1) {
                myError = 'AUTH.INVITE.ERROR';
              } else if (resCheck.indexOf('otptoken is invalid') !== -1) {
                myError = 'AUTH.TWOFACTOR.ERROR';
              } else if (resCheck.indexOf('magiclink token not valid') !== -1) {
                myError = 'AUTH.RECOVER.ERROR';
              } else if (resCheck.indexOf('contract is locked') !== -1) {
                myError = 'AUTH.LOGIN.LOCKED';
              } else {
                myError = 'AUTH.LOGIN.INVALID_CREDENTIALS';
              }
              break;

            case 418:
            case 419:
              if (response.error.body.auto_login_settings) {
                this.setLoginSettings(response.error.body.auto_login_settings);
              }

              this.setProjects(response.error.body.projects, response.error.body.auto_login_settings);

              if (response.error.body.partner && response.error.body.partner.partner_uuid) {
                this.setPartner(response.error.body.partner);
              }

              await this.router.navigate(['/Access/projects']);
              break;

            case 423:
              await this.router.navigate(['/Access/twofactor']);
              break;
          }

          observer.error(myError);
          observer.complete();
        }
      );
    });
  }

  private setPartner(partner: LoginPartner) {
    log('%c[Auth Service] Set Partner', 'color:orange;', partner);
    this.myPartner.next(partner);
  }

  private setProjects(projects: LoginProject[], autoLoginSettings: any = null) {
    const myProjects = [];

    log('%c[Auth Service] Set Projects', 'color:orange;', projects, autoLoginSettings);

    for (const key in projects) {
      if (projects[key]) {
        let name = 'default';

        if (projects[key].contract_name && projects[key].contract_name !== 'default') {
          name = projects[key].contract_name;
        } else if (projects[key].project_name && projects[key].project_name !== 'default') {
          name = projects[key].project_name;
        } else if (projects[key].name && projects[key].name !== 'default') {
          name = projects[key].name;
        }

        const myDate = typeof projects[key].project_date === 'string' ? new Date(Date.parse(projects[key].project_date as unknown as string)) : projects[key].project_date;

        myProjects.push({
          uuid: key,
          name,
          date: projects[key].project_name === 'default' ? myDate : null,
          autologin_uuid: projects[key]
        });
      }
    }

    log('%c[Auth Service] Set Projects Cleaned Object', 'color:orange;', myProjects);
    this.myProjects.next(myProjects as any);
  }

  /**
   * Extract the real uuid from the settings
   *
   * @param _auto_login_settings Settings Object from 418
   */
  setLoginSettings(_auto_login_settings: any) {
    if (_auto_login_settings.selected_auto_login_option_uuid) {
      const option_details = find(_auto_login_settings.auto_login_options, { auto_login_option_uuid: _auto_login_settings.selected_auto_login_option_uuid });
      if (option_details && option_details.auto_login_option_object_uuid) {
        this.myDefaultLoginSetting.next(option_details.auto_login_option_object_uuid);
      }
    }
  }
}
