if (typeof require !== 'undefined') {
  require('es6-promise').polyfill();
  require('isomorphic-fetch');
}
export class GSError extends Error {
  result;
  success = false;
  response;
  constructor(message, result) {
    super();
    this.name = 'GridscaleError';
    // try to assemble message with more details from result
    if (result.response && result.response.request && result.response.request.method && typeof result.response.status !== 'undefined' && result.response.request.url) {
      this.message = 'Error : ' + result.response.request.method + ' | ' + result.response.status + ' | ' + result.response.request.url.split('?')[0];
    } else {
      this.message = message || 'Default Message';
    }
    this.result = result;
    this.response = result.response || undefined;
  }
}
export class APIClass {
  // Local Settings
  settings = {
    endpoint: 'https://api.gridscale.io',
    endpointOverrides: {},
    token: '',
    userId: '',
    limit: 25,
    watchdelay: 1000,
    apiClient: 'gsclient-js'
  };
  /**
   * Store api client in current session
   * @param _client  String
   */
  storeClient(_client) {
    this.settings.apiClient = _client;
  }
  /**
   * Store Token for Current Session
   * @param _token Secret Token
   */
  storeToken(_token, _userId) {
    // Store Token
    this.settings.token = _token;
    this.settings.userId = _userId;
  }
  /**
   * Update local Request Options
   *
   * @param _option
   */
  setOptions = _option => {
    // Assign new Values
    this.settings = {
      ...this.settings,
      ..._option
    };
  };
  /**
   * Start the API Request
   *
   * @param _path
   * @param _options
   * @param _callback
   * @returns {Promise}
   */
  request(_path = '', _options, _callback) {
    const options = {
      ..._options
    };
    // check if we should use another endpoint for this path (mocking)
    var endpoint = this.settings.endpoint;
    if (this.settings.endpointOverrides && typeof this.settings.endpointOverrides === 'object') {
      for (let _overridePath in this.settings.endpointOverrides) {
        if (this.settings.endpointOverrides.hasOwnProperty(_overridePath)) {
          const _overrideEndpoint = this.settings.endpointOverrides[_overridePath];
          if (_overridePath.match(/^\/(.*)\/$/) && _path.split('?')[0].match(new RegExp(RegExp.$1))) {
            endpoint = _overrideEndpoint;
            break;
          } else if (_path.split('?')[0] === _overridePath) {
            endpoint = _overrideEndpoint;
            break;
          }
        }
      }
    }
    // Build Options
    const url = _path.search('https://') === 0 ? _path : endpoint + _path; // on Links there is already
    options.headers = {
      ...(options.headers || {}),
      'X-Auth-UserId': this.settings.userId,
      'X-Auth-Token': this.settings.token,
      'X-Api-Client': this.settings.apiClient,
      ...(this.settings.additionalHeaders || {})
    };
    // return results as object or text
    const getResult = (_response, _rejectOnJsonFailure = true) => {
      return new Promise((_resolve, _reject) => {
        if (_response.status !== 204 && _response.headers.has('Content-Type') && _response.headers.get('Content-Type').indexOf('application/json') === 0) {
          _response.json().then(json => {
            _resolve(json);
          }).catch(() => {
            if (_rejectOnJsonFailure) {
              _reject();
            } else {
              // try text
              _response.text().then(text => _resolve(text)).catch(e => _resolve(null));
            }
          });
        } else {
          _response.text().then(text => _resolve(text)).catch(e => _resolve(null));
        }
      });
    };
    // Setup DEF
    const def = new Promise((_resolve, _reject) => {
      // Fire Request
      const onSuccess = (_response, _request, _requestInit) => {
        getResult(_response.clone()).then(_result => {
          const result = {
            success: true,
            result: _result,
            response: _response.clone(),
            id: null,
            requestInit: _requestInit
          };
          // Check for Links and generate them as Functions
          if (_result && _result._links) {
            const links = {};
            for (let linkname in _result._links) {
              if (_result._links.hasOwnProperty(linkname)) {
                links[linkname] = this.link(_result._links[linkname]);
              }
            }
            result.links = links;
          }
          if (_result && _result._meta) {
            result.meta = _result._meta;
          }
          /**
           * On POST, PATCH and DELETE Request we will inject a watch Function into the Response so you can easiely start watching the current Job
           */
          if (options['method'] === 'POST' || options['method'] === 'PATCH' || options['method'] === 'DELETE') {
            if (result.response.headers.has('x-request-id')) {
              result.watch = () => this.watchRequest(result.response.headers.get('x-request-id'));
            }
          }
          _resolve(result);
          if (_callback !== undefined) {
            setTimeout(() => _callback(_response.clone(), result));
          }
        }).catch(() => {
          // tslint:disable-next-line: no-use-before-declare
          onFail(_response, _request, _requestInit, 'json');
        });
      };
      let errorCounter = 0;
      const onFail = (_response, _request, _requestInit, _failType = 'request') => {
        getResult(_response.clone(), false).then(_result => {
          const result = {
            success: false,
            result: _result,
            response: Object.assign(_response.clone(), {
              request: _request
            }),
            links: {},
            watch: null,
            id: 'apierror_' + new Date().getTime() + '_' + errorCounter,
            requestInit: _requestInit,
            failureType: _failType
          };
          ++errorCounter;
          this.log({
            result: result,
            response: _response.clone(),
            id: result.id,
            requestInit: result.requestInit
          });
          _reject(new GSError('Request Error', result));
          if (_callback !== undefined) {
            setTimeout(() => _callback(_response.clone(), result));
          }
        });
      };
      const request = new Request(url, options);
      const promise = (this.settings.fetch || fetch)(request);
      promise.then(_response => {
        if (_response.ok) {
          // The promise does not reject on HTTP errors
          onSuccess(_response, request, options);
        } else {
          onFail(_response, request, options);
        }
      }).catch(_response => {
        _reject(new GSError('Network failure', _response));
      });
      // Return promise
      return promise;
    });
    // Catch all Errors and
    // Return DEF
    return def;
  }
  /**
   * Build Option URL to expand URL
   * @param _options
   * @returns {string}
   */
  buildRequestURL(_options) {
    // Push Valued
    const url = [];
    // Add Options to URL
    for (let key in _options) {
      if (_options.hasOwnProperty(key)) {
        if (_options[key] !== undefined) {
          if (typeof _options[key] === 'object' && typeof _options[key].length === 'number') {
            if (_options[key].length > 0) {
              url.push(key + '=' + _options[key].join(','));
            }
          } else {
            url.push(key + '=' + _options[key]);
          }
        }
      }
    }
    ;
    return url.length > 0 ? '?' + url.join('&') : '';
  }
  /**
   * Start Get Call
   * @param _path
   * @param _callback
   */
  get(_path, _options, _callback) {
    if (typeof _options === 'object' && _options !== null) {
      _path += this.buildRequestURL(_options);
    }
    // If No Options but Callback is given
    if (_callback === undefined && typeof _options === 'function') {
      _callback = _options;
    }
    return this.request(_path, {
      method: 'GET'
    }, _callback);
  }
  /**
   * Start Delete Call
   * @param _path
   * @param _callback
   */
  remove(_path, _callback) {
    return this.request(_path, {
      method: 'DELETE'
    }, _callback);
  }
  /**
   * Send Post Request
   *
   * @param _path Endpoint
   * @param _attributes  Attributes for Post Body
   * @param _callback Optional Callback
   * @returns {Promise}
   */
  post(_path, _attributes, _callback) {
    return this.request(_path, {
      method: 'POST',
      body: JSON.stringify(_attributes),
      headers: {
        'Content-Type': 'application/json'
      }
    }, _callback);
  }
  /**
   * Send PAtCH Request
   *
   * @param _path Endpoint
   * @param _attributes  Attributes for Post Body
   * @param _callback Optional Callback
   * @returns {Promise}
   */
  patch(_path, _attributes, _callback) {
    return this.request(_path, {
      method: 'PATCH',
      body: JSON.stringify(_attributes),
      headers: {
        'Content-Type': 'application/json'
      }
    }, _callback);
  }
  /**
   * Generate URL for Linked Request. No Options are required because its in the URL already
   *
   * @param _link
   * @param _callback
   * @returns {Function}
   */
  link(_link) {
    /**
     * generate Function that has an Optional Callback
     */
    return _callback => {
      return this.request(_link.href, {
        method: 'GET'
      }, _callback);
    };
  }
  /**
   * Start Pooling on Request Endpoint
   *
   *
   * @param _requestid
   * @param _callback
   * @returns {Promise}
   */
  requestpooling(_requestid, _callback) {
    return this.request('/requests/' + _requestid, {
      method: 'GET'
    }, _callback);
  }
  /**
   * Recursive creating of Request Proises
   *
   *
   * @param _requestid
   * @param _resolve
   * @param _reject
   */
  buildAndStartRequestCallback(_requestid, _resolve, _reject) {
    /**
     * Start new Request
     */
    this.requestpooling(_requestid).then(_result => {
      // Check Request Status to Decide if we start again
      if (_result.result[_requestid].status === 'pending') {
        setTimeout(() => {
          this.buildAndStartRequestCallback(_requestid, _resolve, _reject);
        }, this.settings.watchdelay);
      } else if (_result.response.status === 200) {
        // Job done
        _resolve(_result);
      } else {
        // IF
        _reject(_result);
      }
    }, _result => _reject(_result));
  }
  /**
   * Watch a Single Request until it is ready or failed
   *
   * @param _requestid
   * @param _callback
   */
  watchRequest(_requestid) {
    return new Promise((_resolve, _reject) => {
      this.buildAndStartRequestCallback(_requestid, _resolve, _reject);
    });
  }
  callbacks = [];
  /**
   * Adds a new logger for error logging
   * @param _callback
   */
  addLogger = _callback => {
    this.callbacks.push(_callback);
  };
  log = _logData => {
    for (var i = 0; i < this.callbacks.length; i++) {
      this.callbacks[i](_logData);
    }
  };
}
export const api = new APIClass();
