import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  private myInviteToken?: string;

  constructor(private readonly http: HttpClient) {}

  getInfos(token: string): Observable<any> {
    return new Observable(observer => {
      this.http.post(`/invalidateinvite/${token}`, null).subscribe(
        (res: any) => {
          if (res.body.invitetoken) {
            const data = {
              token: res.body.invitetoken.token,
              email: res.body.invitetoken.inviter_email
            };

            this.myInviteToken = data.token;

            observer.next(data);
            observer.complete();
          }
        },
        err => {
          if (err.error.code) {
            if (err.error.code === 400) {
              observer.error('AUTH.INVITE.INVALID_TOKEN');
            } else if (err.error.code === 401) {
              observer.error('AUTH.INVITE.TOKEN_EXPIRED');
            } else {
              observer.error('AUTH.GENERAL.UNKOWN_ERROR');
            }
          } else {
            observer.error('AUTH.INVITE.NOT_ALLOWED');
          }

          observer.complete();
        }
      );
    });
  }

  resetInviteToken() {
    delete this.myInviteToken;
  }

  get inviteToken() {
    return this.myInviteToken;
  }
}
