class ObjectStorage {
  // Naming
  _api;
  /**
   * Create Object Endpoint
   *
   *
   * @param _api API Class Instance
   * @param _path Path to the Object
   */
  constructor(_api) {
    this._api = _api;
  }
  /**
   * List Access Keys
   *
   *
   * @param _options
   * @param _callback
   * @returns {Promise<ApiResult<models.AccessKeysGetResponse>>}
   */
  accessKeys(_options, _callback) {
    return this._api.get('/objects/objectstorages/access_keys', _options, _callback);
  }
  /**
   * Get Single Object by UUID
   *
   * @param _uuid
   * @param _callback
   */
  accessKey(_access_key, _callback) {
    return this._api.get('/objects/objectstorages/access_keys/' + _access_key, _callback);
  }
  /**
   * Remove Access Key
   *
   * @param _uuid
   * @param _callback
   */
  removeAccessKey(_access_key, _callback) {
    return this._api.remove('/objects/objectstorages/access_keys/' + _access_key, _callback);
  }
  /**
   * Creates new Access Key
   *
   * @param _options
   * @param _callback
   * @returns {Promise<ApiResult<models.AccessKeyCreateResponse>>}
   */
  createAccessKey(_attributes, _callback) {
    return this._api.post('/objects/objectstorages/access_keys', _attributes, _callback);
  }
  /**
   * patches Access Key
   *
   * @param _options
   * @param _callback
   */
  patchAccessKey(_access_key, _attributes, _callback) {
    return this._api.patch('/objects/objectstorages/access_keys/' + _access_key, _attributes, _callback);
  }
  /**
   * List Buckets
   *
   *
   * @param _options
   * @param _callback
   * @returns {Promise<ApiResult<models.AccessKeysGetResponse>>}
   */
  buckets(_options, _callback) {
    return this._api.get('/objects/objectstorages/buckets', _options, _callback);
  }
}
export { ObjectStorage };
