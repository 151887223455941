import { GridscaleObjects } from './GridscaleObjects.js';
class Network extends GridscaleObjects {
  constructor(_api) {
    super(_api, '/objects/networks');
  }
  /**
   * List pinned servers
   *
   * @param _network_uuid
   * @param _callback
   * @returns {Promise<ApiResult<VoidApiResult>>}
   */
  pinnedServers(_network_uuid, _callback) {
    return this._api.get(this._basepath + '/' + _network_uuid + '/pinned_servers', _callback);
  }
  /**
   * Pin a server to an ip
   *
   * @param _network_uuid
   * @param _server_uuid
   * @param _ip
   * @param _callback
   * @returns {Promise<ApiResult<VoidApiResult>>}
   */
  pinServerIp(_network_uuid, _server_uuid, _payload, _callback) {
    return this._api.patch(this._basepath + '/' + _network_uuid + '/pinned_servers/' + _server_uuid, _payload, _callback);
  }
  /**
   * Unpin a server from an ip
   *
   * @param _network_uuid
   * @param _server_uuid
   * @param _ip
   * @param _callback
   * @returns {Promise<ApiResult<VoidApiResult>>}
   */
  unpinServerIp(_network_uuid, _server_uuid, _callback) {
    return this._api.remove(this._basepath + '/' + _network_uuid + '/pinned_servers/' + _server_uuid, _callback);
  }
}
export { Network };
