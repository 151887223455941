import { PaasServiceTemplate } from './PaasServiceTemplate.js';
import { PaasSecurityZone } from './PaasSecurityZone.js';
import { PaasService } from './PaasService.js';
/**
 * this class is only a wrapper to the PaasService, PaasServiceTemplate and PaasSecurityZone classes, due to historical reasons...
 * @deprecated
 */
class PAAS {
  _api;
  serviceTemplates;
  securityZones;
  services;
  constructor(_api) {
    this._api = _api;
    this.serviceTemplates = new PaasServiceTemplate(this._api);
    this.securityZones = new PaasSecurityZone(this._api);
    this.services = new PaasService(this._api);
  }
}
export { PAAS };
